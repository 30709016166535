import React, { Component } from 'react'
import ServiceIcon from './ServiceIcon'
import ServiceDetails from './ServiceDetails'
import './css/Service.css'

class Option extends Component {
  render() {
    return (
      <div className="Service flexItem nestedFlexContainer" onClick={ 
        () =>
        {
          if(this.props.url)
            window.location.href = this.props.url
        }
      }>
        <ServiceIcon icon={this.props.i}/>
        <ServiceDetails service={this.props.service}/>
      </div>
    )
  }
}

export default Option
