import React, { Component } from 'react'
import './css/ServiceDetails.css'

class ServiceDetails extends Component {
    render() {
        let service = this.props.service
        return (
            <div className="ServiceDetails" >
                <div className='ServiceName'>{service.name}</div>
                <div className='ServiceDescription'>{service.description}</div>
            </div>
        )
    }
}

export default ServiceDetails
