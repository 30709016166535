import React, { Component } from 'react'
import Video from './Video'
import Image from './Image'

import './css/Cover.css'

class Cover extends Component {
  render() {    
    return (
      <div className="Cover" >
        {this.props.lang === 'de' ? <Video /> : <Image /> }
      </div>
    )
  }
}

export default Cover
