import React, { Component } from 'react'
import './css/Image.css'

class Image extends Component {
    render() {
        return (
            <div className='Image' >
            </div>
        )
    }
}

export default Image
