import React, { Component } from 'react'
import services from './lang/Services'
import Service from './Service'
import './css/Selector.css'

const map = {
  'service-meldung' : 'msg',
  'anleitung'       : 'doc',
  'hotline'         : 'tel',
  'faq'             : 'faq'
}

class Selector extends Component {

  getURLParameter(name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=([^&;]+?)(&|#|;|$)').exec(window.location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
  }

  render() {
    let lang   = this.props.lang
    let tabs = this.getURLParameter('tabs').split('|')
    let pid  = this.getURLParameter('pid')
    let url  = 'http://my-qr.io/nn_debug/?fromSS=true&type=nntype&pid=' + pid + '&tab='

    return (
      <div className="Selector flexContainer" >
      {
        tabs.map( (id, i) => {
            return <Service key={i} service={services[map[id]][lang]} i={ map[id] } url={ id === 'faq' ? '' : url + id }/>
        })
      }        
      </div>
    )
  }
}

export default Selector
