import React, { Component } from 'react'
import Header from './Header'
import Cover from './Cover'
import Selector from './Selector'
import './css/ServiceSelector.css'

class ServiceSelector extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            language: 'de'
        }
    }


    render() {
        let l = this.state.language
        return (
            <div className="ServiceSelector">
                <Header lang={l} parent={this} />
                <Cover lang={l} />
                <Selector lang={l}/>
            </div>
        )
    }
}

export default ServiceSelector
